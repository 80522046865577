// import React from "react";

// const SubChapter = () => {
//     return(
//         <>
//         <div className="container mt-4">
//             <h3>Chapter Name</h3>
//             <div className="row">
//                 <div className="col-lg-12">
//                 <div class="card">
//                 <div class="card-body">
//                     <div className="row">
//                         <div className="col-lg-8">
//                             1.1 Physical Resources of Nepal
//                         </div>
//                         <div className="col-lg-4">
//                         <div class="btn-group" role="group" aria-label="Basic example">
//                             <button type="button" class="btn btn-secondary">Practice</button>
//                             <button type="button" class="btn btn-secondary">View Results</button>
//                             <button type="button" class="btn btn-secondary">Right</button>
//                         </div>
//                         </div>
//                     </div>
                    
//                 </div>
//             </div>
//                 </div>
//             </div>
//             <div className="row">
//                 <div className="col-lg-12">
//                 <div class="card">
//                 <div class="card-body">
//                     <div className="row">
//                         <div className="col-lg-8">
//                             1.1 Physical Resources of Nepal
//                         </div>
//                         <div className="col-lg-4">
//                         <div class="btn-group" role="group" aria-label="Basic example">
//                             <button type="button" class="btn btn-secondary">Practice</button>
//                             <button type="button" class="btn btn-secondary">View Results</button>
//                             <button type="button" class="btn btn-secondary">Right</button>
//                         </div>
//                         </div>
//                     </div>
                    
//                 </div>
//             </div>
//                 </div>
//             </div>
            

//             </div>
       
//         </>
//     );
// };

// export default SubChapter


import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import SubChaperWiseExam from './exams/SubChapterWiseExam';
import { useHistory } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import SubChapterTestResult from './results/SubChapterTestResult';


const SubChapter = (props) => {
  const [subChapters, setSubChapters] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const location = useLocation();

  const chapterCode = location.state?.chapterCode;
  const chapterId = location.state?.chapterId;
  
  const courseCode = location.state?.courseCode;


  const [subChapterCode, setsubChapterCode] = useState(null);
  const [subChapterId, setsubChapterId] = useState(null);

  const history = useHistory();

  useEffect(() => {
    // Fetch subchapters from the API based on chapterCode
    console.log(chapterCode);
    const fetchSubChapters = async () => {
      try {
        const response = await fetch(
          `https://nec.geoneer.com.np/api/exam/subchapters/${courseCode}/${chapterCode}/`
          
        );
        if (!response.ok) {
          throw new Error("Failed to fetch subchapters");
        }
        const data = await response.json();
        setSubChapters(data); // Update subChapters state
      } catch (err) {
        setError(err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchSubChapters();
  }, [chapterCode,courseCode]); // Re-fetch data when chapterCode changes




  const handleTakeExamChapterWise = (subChapterCode,chapterCode,subChapterId) => {
    setsubChapterCode(subChapterCode);
    setsubChapterId(subChapterId);
    // Use history.push to navigate to the '/chapterwise-exam' route with props
    history.push({
      pathname: '/sub-chapterwise-exam ',
      
      state: {
        subChapterCode: subChapterCode,
        chapterCode: chapterCode,
        chapterId: chapterId,
        subChapterId: subChapterId,
        // Add other props as needed
      },
    });
  };



  const handleTakeExamChapterWiseResult = (subChapterCode,chapterCode,subChapterId) => {
    setsubChapterCode(subChapterCode);
    setsubChapterId(subChapterId);
    // Use history.push to navigate to the '/chapterwise-exam' route with props
    history.push({
      pathname: '/sub-chapter-test-results',
      
      state: {
        subChapterCode: subChapterCode,
        chapterCode: chapterCode,
        chapterId: chapterId,
        subChapterId: subChapterId,
        // Add other props as needed
      },
    });
  };



  return (
    <div className="container mt-4">
      <h3>Subchapters for Chapter: {chapterCode}</h3>

      {/* Display loading state */}
      {loading && <p>Loading subchapters...</p>}
      
      {error && <p className="text-danger">Error: {error}</p>}

      {/* Dynamically render subchapters */}
      <div className="row">
        {subChapters.map((subChapter) => (
          <div className="col-lg-12" key={subChapter.id}>
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    {/* {subChapter.subChapterCode}  */}
                    {subChapter.subChapterName}
                  </div>
                  <div className="col-lg-4">
                    <div className="btn-group" role="group" aria-label="Basic example">
                    <Button variant="success" onClick={() => handleTakeExamChapterWise(subChapter.subChapterCode,chapterCode,subChapter.id)}>
                  {console.log(subChapter.subChapterCode)}
                  {console.log(chapterCode)}
                  {console.log('chapterId is ',chapterId)}
                  {console.log('subchapterId is ',subChapter.id)}
                Practice
                
              </Button>
{/*               
              <a href="/sub-chapter-test-results" className="btn btn-success btn-sm">
                    View Results
                  </a> */}

<Button variant="success" onClick={() => handleTakeExamChapterWiseResult(subChapter.subChapterCode,chapterCode,subChapter.id)}>
                  {console.log(subChapter.subChapterCode)}
                  {console.log(chapterCode)}
                  {console.log('chapterId is ',chapterId)}
                  {console.log('subchapterId is ',subChapter.id)}
                  View Results
                
              </Button>
                      {/* <button type="button" className="btn btn-secondary">
                        Right
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>

      {subChapterCode!== null && (
        <SubChaperWiseExam
        subChapterCode={subChapterCode}
        chapterCode ={chapterCode}
        chapterId ={chapterId}
        subChapterId = {subChapterId}
        courseCode ={courseCode}
          
        />
      )}



{subChapterCode!== null && (
        <SubChapterTestResult
        subChapterCode={subChapterCode}
        chapterCode ={chapterCode}
        chapterId ={chapterId}
        subChapterId = {subChapterId}
        courseCode ={courseCode}
          
        />
      )}
    </div>
  );
};

export default SubChapter;



