// import React, { useState, useEffect, useRef} from 'react';
// import axios from 'axios';
// import '../../components/quiz.css';
// import Swal from 'sweetalert2';
// import parse from 'html-react-parser';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// const MockTestExam = (props) => {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);
//   const [selectedAnswers, setSelectedAnswers] = useState({});
//   const questionRef = useRef(null);
//   const [showResultButton, setShowResultButton] = useState(false);
//   const [optionStyle, setOptionStyle] = useState({});
//   const [showResultDetails, setShowResultDetails] = useState(false);
//   const [disabledQuestions, setDisabledQuestions] = useState([]);
//   let questionNumber = 1; 
//   const location = useLocation();
//   // const chapterId = location.state?.chapterId;
// //   const additionalProp = location.state?.additionalProp;
//   const courseCode = location.state?.courseCode;

//   const handleResultSubmit = (e) => {
//     e.preventDefault(); // Prevent default form submission
//     setShowResultButton(true);
//     setDisabledQuestions(Object.keys(selectedAnswers));
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (!showResultButton) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };
  
//     window.addEventListener('beforeunload', handleBeforeUnload);
  
//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [showResultButton]);
  

//   useEffect(() => {
//     axios
//       .get(` https://nec.geoneer.com.np/api/exam/mock-tests/${courseCode}/ `)
//       .then(response => {
//         setIsLoaded(true);
//         setItems(response.data);
//       })
//       .catch(error => {
//         setIsLoaded(true);
//         setError(error);
//       });
//   },[courseCode]);

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width;
//       const optionStyle = {
//         width: questionWidth + 'px',
//       };
//       setOptionStyle(optionStyle);
//     }
//   }, [items]);

//   const mergedItems = items.reduce((acc, item) => {
//     if (!acc[item.id]) {
//       acc[item.id] = item;
//     } else {
//       acc[item.id] = { ...acc[item.id], ...item };
//     }
//     return acc;
//   }, {});

//   const mergedItemsArray = Object.values(mergedItems);

//   const handleOptionClick = (itemId, option) => {
//     // Check if the option is already selected for the given question
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers(prevState => ({
//         ...prevState,
//         [itemId]: option.toString(), // Convert the option to a string
//       }));
//     }
//   };

//   const getOptionClassName = (itemId, option) => {
//     const item = mergedItemsArray.find(item => item.id === itemId);
//     const selectedOption = selectedAnswers[itemId];

//     if (selectedOption === option.toString()) {
//       if (showResultButton) {
//         if (selectedOption !== item.correctOpt.toString()) {
//           return 'option wrong';
//         }
//       }

//       return 'option selected';
//     } else if (disabledQuestions.includes(itemId)) {
//       // Disable the option if the question is disabled
//       return 'option disabled';
//     } else {
//       return 'option';
//     }
//   };

//   const handleShowResultClick = () => {
//     const count = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
//       const item = mergedItemsArray[index];
//       if (selectedOption === item.correctOpt.toString()) {
//         acc++;
//       }
//       return acc;
//     }, 0);

//     setShowResultDetails(true);

//     Swal.fire({
//       title: 'Result',
//       text: `You answered ${count} questions correctly!`,
//       icon: 'info',
//     }).then(() => {
//       // Store quiz data in local storage
//       const quizData = {
//         date: new Date().toLocaleString(),
//         score: count,
//       };
//       localStorage.setItem('quizData', JSON.stringify(quizData));
//     });
//   };

//   const attendedQuestionsCount = Object.keys(selectedAnswers).length;

//   return (
//     <div className="quiz-container">
      
    
//       <h6>Practice Questions for    <strong></strong> </h6>
//       <br></br>

      

//       <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

//       {error && <div>Error: {error.message}</div>}

//       {!isLoaded && !error && <div>Loading...</div>}

//       {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}
//       {isLoaded && mergedItemsArray.length > 0 && (
//   <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
//     {mergedItemsArray.map(item => (
//       item.chapters.map(chapter => (
//         chapter.questions.map(question => (
//           <li key={question.id}>
//             <br />
//             <h6>
//               <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//                 <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
//                   Q {questionNumber++}:
//                 </span>
//                 <span style={{ flex: 1 }}>
//                   {parse(question.question)}
//                 </span>
//               </div>
//             </h6>

//             <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
//               {[1, 2, 3, 4].map(optionNumber => (
//                 <li
//                   key={optionNumber}
//                   className={getOptionClassName(question.id, optionNumber.toString())}
//                   onClick={() => handleOptionClick(question.id, optionNumber.toString())}
//                   style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//                 >
//                   <span className="option-text">{parse(question[`option${optionNumber}`])}</span>
//                 </li>
//               ))}
//             </ul>
//             {showResultDetails && (
//               <div className="result-details">
//                 <p>Correct Option: {question.correctOpt}</p>
//                 <div className="result-details" style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//                   <p>Explanation: {parse(question.explanation)}</p>
//                 </div>
//               </div>
//             )}
//           </li>
//         ))
//       ))
//     ))}
//   </ul>
// )}

//       {attendedQuestionsCount === mergedItemsArray.length && !showResultButton && (
//         <button className="submit-button" onClick={handleResultSubmit}>Submit</button>
//       )}

//       {showResultButton && (
//         <button className="show-result-button" onClick={handleShowResultClick}>Show Result</button>
//       )}
//     </div>
//   );
// };

// export default MockTestExam;


// import React, { useState, useEffect, useRef } from 'react';
// import axios from 'axios';
// import '../../components/quiz.css';
// import Swal from 'sweetalert2';
// import parse from 'html-react-parser';
// import { useLocation } from 'react-router-dom';

// const MockTestExam = (props) => {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);
//   const [selectedAnswers, setSelectedAnswers] = useState({});
//   const questionRef = useRef(null);
//   const [showResultButton, setShowResultButton] = useState(false);
//   const [optionStyle, setOptionStyle] = useState({});
//   const [showResultDetails, setShowResultDetails] = useState(false);
//   const [disabledQuestions, setDisabledQuestions] = useState([]);
//   let questionNumber = 1;
//   const location = useLocation();
//   const courseCode = location.state?.courseCode;

//   const handleResultSubmit = (e) => {
//     e.preventDefault();
//     setShowResultButton(true);
//     setDisabledQuestions(Object.keys(selectedAnswers));
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (!showResultButton) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [showResultButton]);

//   useEffect(() => {
//     // Fetch mock test data using courseCode
//     axios
//       .get(`https://nec.geoneer.com.np/api/exam/mock-tests/SO-PSC/`)
//       .then((response) => {
//         setIsLoaded(true);
//         if (Array.isArray(response.data)) {
//           setItems(response.data);
//         } else {
//           setError('Invalid response format');
//         }
//       })
//       .catch((error) => {
//         setIsLoaded(true);
//         setError(error);
//       });
//   }, []);

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width;
//       const optionStyle = {
//         width: `${questionWidth}px`,
//       };
//       setOptionStyle(optionStyle);
//     }
//   }, [items]);

//   const handleOptionClick = (itemId, option) => {
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers((prevState) => ({
//         ...prevState,
//         [itemId]: option.toString(),
//       }));
//     }
//   };

//   const getOptionClassName = (itemId, option) => {
//     const selectedOption = selectedAnswers[itemId];
//     if (selectedOption === option.toString()) {
//       return showResultButton
//         ? selectedOption !== items.find((item) => item.id === itemId)?.correctOpt?.toString()
//           ? 'option wrong'
//           : 'option selected'
//         : 'option selected';
//     }
//     return disabledQuestions.includes(itemId) ? 'option disabled' : 'option';
//   };

//   const handleShowResultClick = () => {
//     const correctCount = items.reduce((count, item) => {
//       const selectedOption = selectedAnswers[item.id];
//       if (selectedOption === item.correctOpt?.toString()) count++;
//       return count;
//     }, 0);

//     setShowResultDetails(true);

//     Swal.fire({
//       title: 'Result',
//       text: `You answered ${correctCount} questions correctly!`,
//       icon: 'info',
//     }).then(() => {
//       const quizData = {
//         date: new Date().toLocaleString(),
//         score: correctCount,
//       };
//       localStorage.setItem('quizData', JSON.stringify(quizData));
//     });
//   };

//   const attendedQuestionsCount = Object.keys(selectedAnswers).length;

//   return (
//     <div className="quiz-container">
//       <h6>Practice Questions for <strong>{courseCode}</strong></h6>
//       <br />
//       <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

//       {error && <div>Error: {error}</div>}

//       {!isLoaded && !error && <div>Loading...</div>}

//       {isLoaded && items.length === 0 && <div>No items to display</div>}

//       {isLoaded && items.length > 0 && (
//         <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
//           {items.map((item) =>
//             item?.chapters?.map((chapter) =>
//               chapter?.questions?.map((question) => (
//                 <li key={question.id}>
//                   <br />
//                   <h6>
//                     <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//                       <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
//                         Q {questionNumber++}:
//                       </span>
//                       <span style={{ flex: 1 }}>
//                         {parse(question.question || '')}
//                       </span>
//                     </div>
//                   </h6>

//                   <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
//                     {[1, 2, 3, 4].map((optionNumber) => (
//                       <li
//                         key={optionNumber}
//                         className={getOptionClassName(question.id, optionNumber.toString())}
//                         onClick={() => handleOptionClick(question.id, optionNumber.toString())}
//                         style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//                       >
//                         <span className="option-text">
//                           {parse(question[`option${optionNumber}`] || '')}
//                         </span>
//                       </li>
//                     ))}
//                   </ul>
//                 </li>
//               ))
//             )
//           )}
//         </ul>
//       )}

//       {showResultButton && (
//         <button className="show-result-button" onClick={handleShowResultClick}>
//           Show Results
//         </button>
//       )}
//     </div>
//   );
// };

// export default MockTestExam;



import React, { useState, useEffect, useRef,useContext} from 'react';
import axios from 'axios';
import '../../components/quiz.css';
import Swal from 'sweetalert2';
import AuthContext from "../../context/AuthContext";

import parse from 'html-react-parser';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';

const ChapterUn = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const questionRef = useRef(null);
  const [showResultButton, setShowResultButton] = useState(false);
  const [optionStyle, setOptionStyle] = useState({});
  const [showResultDetails, setShowResultDetails] = useState(false);
  const [disabledQuestions, setDisabledQuestions] = useState([]);
  let questionNumber = 1; 
  // const location = useLocation();
  // const chapterId = location.state?.chapterId;
  // const additionalProp = location.state?.additionalProp;
  // const chapterName = location.state?.chapterName;
  const { authTokens, user } = useContext(AuthContext);
  const [submissionResponse, setSubmissionResponse] = useState(null);



  const handleResultSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    setShowResultButton(true);
    setDisabledQuestions(Object.keys(selectedAnswers));
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!showResultButton) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [showResultButton]);
  

  useEffect(() => {
    axios
      .get(` https://nec.geoneer.com.np/api/exam/mock-tests/SO-PSC/ `)
      .then(response => {
        setIsLoaded(true);
        setItems(response.data);
      })
      .catch(error => {
        setIsLoaded(true);
        setError(error);
      });
  },[]);

  useEffect(() => {
    if (questionRef.current) {
      const questionWidth = questionRef.current.getBoundingClientRect().width;
      const optionStyle = {
        width: questionWidth + 'px',
      };
      setOptionStyle(optionStyle);
    }
  }, [items]);

  const mergedItems = items.reduce((acc, item) => {
    if (!acc[item.id]) {
      acc[item.id] = item;
    } else {
      acc[item.id] = { ...acc[item.id], ...item };
    }
    return acc;
  }, {});

  const mergedItemsArray = Object.values(mergedItems);

  const handleOptionClick = (itemId, option) => {
    if (!selectedAnswers[itemId] && !showResultButton) {
      setSelectedAnswers(prevState => ({
        ...prevState,
        [itemId]: option.toString(), // Convert the option to a string
      }));
    }
  };

  const getOptionClassName = (itemId, option) => {
    const item = mergedItemsArray.find(item => item.id === itemId);
    const selectedOption = selectedAnswers[itemId];

    if (selectedOption === option.toString()) {
      if (showResultButton) {
        if (selectedOption !== item.correctOpt.toString()) {
          return 'option wrong';
        }
      }

      return 'option selected';
    } else if (disabledQuestions.includes(itemId)) {
      return 'option disabled';
    } else {
      return 'option';
    }
  };

  // const handleShowResultClick = () => {
  //   const count = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
  //     const item = mergedItemsArray[index];
  //     if (selectedOption === item.correctOpt.toString()) {
  //       acc++;
  //     }
  //     return acc;
  //   }, 0);

  //   setShowResultDetails(true);

  //   Swal.fire({
  //     title: 'Result',
  //     text: `You answered ${count} questions correctly!`,
  //     icon: 'info',
  //   }).then(() => {
  //     // Store quiz data in local storage
  //     const quizData = {
  //       date: new Date().toLocaleString(),
  //       score: count,
  //     };
  //     localStorage.setItem('quizData', JSON.stringify(quizData));
  //   });
  // };





  const handleShowResultClick = async () => {
    // const correctCount = Object.values(selectedAnswers).reduce(
    //   (acc, selectedOption, index) => {
    //     const item = items[index];
    //     if (selectedOption === item.correctOpt.toString()) {
    //       acc++;
    //     }
    //     return acc;
    //   },
    //   0
    // );


    const correctCount = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
      const item = mergedItemsArray[index];
      if (selectedOption === item.correctOpt.toString()) {
        acc++;
      }
      return acc;
    }, 0);

    
    const totalScore = items.length;
    const userScore = correctCount-0.2*(totalScore-correctCount);

    Swal.fire({
      title: "Result",
      text: `You answered ${correctCount} questions correctly! Your score id ${userScore}`,
      icon: "info",
    });



    // Authorization: Get the token from localStorage

    // Submit the results via a POST request
    try {
      const response = await axios.post(
        `https://nec.geoneer.com.np/api/exam/mocktest-results/5/`,
        {
          user: user.id, // Replace with the authenticated user ID dynamically
          course: 5, // Replace with dynamic course ID
          // chapter: chapterId, // Replace with dynamic chapter ID
          // subChapter: subChapterId, // Dynamic subchapter code
          score: userScore.toFixed(2),
          total_score: totalScore.toFixed(2),
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access}`, // Include the token in the headers
          },
        }
      );
      setSubmissionResponse(response.data);
      Swal.fire("Success", "Quiz results submitted successfully!", "success");
    } catch (error) {
      setSubmissionResponse("Failed to submit quiz results.");
      Swal.fire("Error", "Failed to submit quiz results.", "error");
    }
  };

  const attendedQuestionsCount = Object.keys(selectedAnswers).length;

  return (
    <div className="quiz-container">
      
    
      <h6>Mock Test Questions   </h6>
      <br></br>

      {/* {console.log(chapterName)} */}

      <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

      {error && <div>Error: {error.message}</div>}

      {!isLoaded && !error && <div>Loading...</div>}

      {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

      {isLoaded && mergedItemsArray.length > 0 && (
   <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
          {mergedItemsArray.map(item => (
            <li key={item.id}>
              <br></br>
<h6>
  <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
    <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
      Q {questionNumber++}:
    </span>
    <span style={{ flex: 1 }}>
      {parse(item.question)}
    </span>
  </div>
</h6>

<ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
  <li
    className={getOptionClassName(item.id, "1")}
    onClick={() => handleOptionClick(item.id, "1")}
    style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
  >
    <span className="option-text">{parse(item.option1)}</span>
  </li>
  <li
    className={getOptionClassName(item.id, "2")}
    onClick={() => handleOptionClick(item.id, "2")}
    style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
  >
    <span className="option-text">{parse(item.option2)}</span>
  </li>
  <li
    className={getOptionClassName(item.id, "3")}
    onClick={() => handleOptionClick(item.id, "3")}
    style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
  >
    <span className="option-text">{parse(item.option3)}</span>
  </li>
  <li
    className={getOptionClassName(item.id, "4")}
    onClick={() => handleOptionClick(item.id, "4")}
    style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
  >
    <span className="option-text">{parse(item.option4)}</span>
  </li>
</ul>
{showResultDetails && (
                  <div className="result-details">
                  <p>Correct Option: {item.correctOpt}</p>
                  <div className="result-details"  style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
  <p >Explanation: {parse(item.explanation)}</p>
</div>
                </div>
)}

           
            </li>
          ))}
        </ul>
      )}

      {attendedQuestionsCount === mergedItemsArray.length && !showResultButton && (
        <button className="submit-button" onClick={handleResultSubmit}>Submit</button>
      )}

      {showResultButton && (
        <button className="show-result-button" onClick={handleShowResultClick}>Show Result</button>
      )}


      {console.log(setShowResultDetails)}
      {console.log(submissionResponse)}
    </div>
  );
};

export default ChapterUn;




