import React, { useState, useEffect, useRef,useContext} from 'react';
import axios from 'axios';
import '../../components/quiz.css';
import Swal from 'sweetalert2';
import parse from 'html-react-parser';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import AuthContext from '../../context/AuthContext';


const SubChapterWiseExam = (props) => {
  const [error, setError] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [items, setItems] = useState([]);
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const questionRef = useRef(null);
  const [showResultButton, setShowResultButton] = useState(false);
  const [optionStyle, setOptionStyle] = useState({});
  const [showResultDetails, setShowResultDetails] = useState(false);
  const [disabledQuestions, setDisabledQuestions] = useState([]);
  let questionNumber = 1; 
  const [submissionResponse, setSubmissionResponse] = useState(null);

  const location = useLocation();
  const chapterCode = location.state?.chapterCode;
  const chapterId = location.state?.chapterId;
  const subChapterCode = location.state?.subChapterCode;
  const subChapterId = location.state?.subChapterId;
  const courseCode = location.state?.courseCode;

  

  const { authTokens, user } = useContext(AuthContext);



  console.log(chapterCode);

  const handleResultSubmit = (e) => {
    e.preventDefault(); // Prevent default form submission
    setShowResultButton(true);
    setDisabledQuestions(Object.keys(selectedAnswers));
  };

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (!showResultButton) {
        e.preventDefault();
        e.returnValue = '';
      }
    };
  
    window.addEventListener('beforeunload', handleBeforeUnload);
  
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [showResultButton]);

  
  

  useEffect(() => {
    if (chapterCode && subChapterCode) {
      axios
      .get(` https://nec.geoneer.com.np/api/exam/questions/${courseCode}/${chapterCode}/${subChapterCode}/ `)
      .then(response => {
        setIsLoaded(true);
        setItems(response.data);
      })
      .catch(error => {
        setIsLoaded(true);
        setError(error);
      });
    }

  },[subChapterCode,chapterCode,courseCode]);

  useEffect(() => {
    if (questionRef.current) {
      const questionWidth = questionRef.current.getBoundingClientRect().width;
      const optionStyle = {
        width: questionWidth + 'px',
      };
      setOptionStyle(optionStyle);
    }
  }, [items]);

  const mergedItems = items.reduce((acc, item) => {
    if (!acc[item.id]) {
      acc[item.id] = item;
    } else {
      acc[item.id] = { ...acc[item.id], ...item };
    }
    return acc;
  }, {});

  const mergedItemsArray = Object.values(mergedItems);

  const handleOptionClick = (itemId, option) => {
    // Check if the option is already selected for the given question
    if (!selectedAnswers[itemId] && !showResultButton) {
      setSelectedAnswers(prevState => ({
        ...prevState,
        [itemId]: option.toString(), // Convert the option to a string
      }));
    }
  };

  const getOptionClassName = (itemId, option) => {
    const item = mergedItemsArray.find(item => item.id === itemId);
    const selectedOption = selectedAnswers[itemId];

    if (selectedOption === option.toString()) {
      if (showResultButton) {
        if (selectedOption !== item.correctOpt.toString()) {
          return 'option wrong';
        }
      }

      return 'option selected';
    } else if (disabledQuestions.includes(itemId)) {
      // Disable the option if the question is disabled
      return 'option disabled';
    } else {
      return 'option';
    }
  };

  const handleShowResultClick = async () => {



    const correctCount = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
      const item = mergedItemsArray[index];
      if (selectedOption === item.correctOpt.toString()) {
        acc++;
      }
      return acc;
    }, 0);

    Swal.fire({
      title: 'Result',
      text: `You answered ${correctCount} questions correctly out of ${items.length}!`,
      icon: 'info',
    });

    const totalScore = items.length;
    const userScore = correctCount;
    setShowResultDetails(true);

    try {
      const response = await axios.post(
        `https://nec.geoneer.com.np/api/exam/courses/SO-PSC/chapters/${chapterCode}/subchapters/${subChapterCode}/results/`,
        {
          user: user.id,
          course: 5,
          chapter: chapterId,
          subChapter: subChapterId,
          score: userScore.toFixed(2),
          total_score: totalScore.toFixed(2),
        },
        {
          headers: {
            Authorization: `Bearer ${authTokens?.access}`,
          },
        }
      );
      setSubmissionResponse(response.data);
      Swal.fire('Success', 'Quiz results submitted successfully!', 'success');
    } catch (error) {
      setSubmissionResponse('Failed to submit quiz results.');
      Swal.fire('Error', 'Failed to submit quiz results.', 'error');
    }
  };
  const attendedQuestionsCount = Object.keys(selectedAnswers).length;

  return (
    <div className="quiz-container">
      
    
      <h6>Practice Questions </h6>
      <br></br>

      

      <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

      {error && <div>Error: {error.message}</div>}

      {!isLoaded && !error && <div>Loading...</div>}

      {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

      {isLoaded && mergedItemsArray.length > 0 && (
   <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
          {mergedItemsArray.map(item => (
            <li key={item.id}>
              <br></br>
<h6>
  <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
    <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>
      Q {questionNumber++}:
    </span>
    <span style={{ flex: 1 }}>
      {parse(item.question)}
    </span>
  </div>
</h6>

<ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
  <li
    className={getOptionClassName(item.id, "1")}
    onClick={() => handleOptionClick(item.id, "1")}
    style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
  >
    <span className="option-text">{parse(item.option1)}</span>
  </li>
  <li
    className={getOptionClassName(item.id, "2")}
    onClick={() => handleOptionClick(item.id, "2")}
    style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
  >
    <span className="option-text">{parse(item.option2)}</span>
  </li>
  <li
    className={getOptionClassName(item.id, "3")}
    onClick={() => handleOptionClick(item.id, "3")}
    style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
  >
    <span className="option-text">{parse(item.option3)}</span>
  </li>
  <li
    className={getOptionClassName(item.id, "4")}
    onClick={() => handleOptionClick(item.id, "4")}
    style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
  >
    <span className="option-text">{parse(item.option4)}</span>
  </li>
</ul>
{showResultDetails && (
                  <div className="result-details">
                  <p>Correct Option: {item.correctOpt}</p>
                  <div className="result-details"  style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
  <p >Explanation: {parse(item.explanation)}</p>
</div>
                </div>
)}

           
            </li>
          ))}
        </ul>
      )}

      {attendedQuestionsCount === mergedItemsArray.length && !showResultButton && (
        <button className="submit-button" onClick={handleResultSubmit}>Submit</button>
      )}

      {showResultButton && (
        <button className="show-result-button" onClick={handleShowResultClick}>Show Result</button>
      )}


      {console.log(submissionResponse)}
    </div>
  );
};

export default SubChapterWiseExam;

// import React, { useState, useEffect, useRef, useContext } from 'react';
// import axios from 'axios';
// import '../../components/quiz.css';
// import Swal from 'sweetalert2';
// import parse from 'html-react-parser';
// import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
// import AuthContext from '../../context/AuthContext';
// import { LoadingContext } from '../../context/LoadingContext'; // Import LoadingContext
// import LoadingSpinner from '../LoadingSpinner'; // Import LoadingSpinner

// const SubChapterWiseExam = (props) => {
//   const [error, setError] = useState(null);
//   const [isLoaded, setIsLoaded] = useState(false);
//   const [items, setItems] = useState([]);
//   const [selectedAnswers, setSelectedAnswers] = useState({});
//   const questionRef = useRef(null);
//   const [showResultButton, setShowResultButton] = useState(false);
//   const [optionStyle, setOptionStyle] = useState({});
//   const [showResultDetails, setShowResultDetails] = useState(false);
//   const [disabledQuestions, setDisabledQuestions] = useState([]);
//   let questionNumber = 1;
//   const [submissionResponse, setSubmissionResponse] = useState(null);

//   const location = useLocation();
//   const chapterCode = location.state?.chapterCode;
//   const chapterId = location.state?.chapterId;
//   const subChapterCode = location.state?.subChapterCode;
//   const subChapterId = location.state?.subChapterId;
//   const courseCode = location.state?.courseCode;


//   const { authTokens, user } = useContext(AuthContext);
//   const { isLoading, setIsLoading } = useContext(LoadingContext); // Use LoadingContext

//   console.log(chapterCode);

//   const handleResultSubmit = (e) => {
//     e.preventDefault(); // Prevent default form submission
//     setShowResultButton(true);
//     setDisabledQuestions(Object.keys(selectedAnswers));
//   };

//   useEffect(() => {
//     const handleBeforeUnload = (e) => {
//       if (!showResultButton) {
//         e.preventDefault();
//         e.returnValue = '';
//       }
//     };

//     window.addEventListener('beforeunload', handleBeforeUnload);

//     return () => {
//       window.removeEventListener('beforeunload', handleBeforeUnload);
//     };
//   }, [showResultButton]);

//   useEffect(() => {
//     if (chapterCode && subChapterCode && courseCode) {
     
//       axios
//         .get(`https://nec.geoneer.com.np/api/exam/questions/${courseCode}/${chapterCode}/${subChapterCode}/`)
//         .then((response) => {     
//            setIsLoaded(true);
//           setItems(response.data);
//         })
//         .catch((error) => {
//           setIsLoaded(true);
//           setError(error);
//         })
//         .finally(() => {
//           setIsLoading(false); // Hide loading spinner
//         });
//     }
//   }, [subChapterCode, chapterCode, setIsLoading,courseCode]);

//   useEffect(() => {
//     if (questionRef.current) {
//       const questionWidth = questionRef.current.getBoundingClientRect().width;
//       const optionStyle = {
//         width: questionWidth + 'px',
//       };
//       setOptionStyle(optionStyle);
//     }
//   }, [items]);

//   const mergedItems = items.reduce((acc, item) => {
//     if (!acc[item.id]) {
//       acc[item.id] = item;
//     } else {
//       acc[item.id] = { ...acc[item.id], ...item };
//     }
//     return acc;
//   }, {});

//   const mergedItemsArray = Object.values(mergedItems);

//   const handleOptionClick = (itemId, option) => {
//     // Check if the option is already selected for the given question
//     if (!selectedAnswers[itemId] && !showResultButton) {
//       setSelectedAnswers((prevState) => ({
//         ...prevState,
//         [itemId]: option.toString(), // Convert the option to a string
//       }));
//     }
//   };

//   const getOptionClassName = (itemId, option) => {
//     const item = mergedItemsArray.find((item) => item.id === itemId);
//     const selectedOption = selectedAnswers[itemId];

//     if (selectedOption === option.toString()) {
//       if (showResultButton) {
//         if (selectedOption !== item.correctOpt.toString()) {
//           return 'option wrong';
//         }
//       }

//       return 'option selected';
//     } else if (disabledQuestions.includes(itemId)) {
//       // Disable the option if the question is disabled
//       return 'option disabled';
//     } else {
//       return 'option';
//     }
//   };

//   const handleShowResultClick = async () => {
//     setIsLoading(true); // Show loading spinner
//     const correctCount = Object.values(selectedAnswers).reduce((acc, selectedOption, index) => {
//       const item = mergedItemsArray[index];
//       if (selectedOption === item.correctOpt.toString()) {
//         acc++;
//       }
//       return acc;
//     }, 0);

//     Swal.fire({
//       title: 'Result',
//       text: `You answered ${correctCount} questions correctly out of ${items.length}!`,
//       icon: 'info',
//     });

//     const totalScore = items.length;
//     const userScore = correctCount;
//     setShowResultDetails(true);

//     try {
//       const response = await axios.post(
//         `https://nec.geoneer.com.np/api/exam/courses/SO-PSC/chapters/${chapterCode}/subchapters/${subChapterCode}/results/`,
//         {
//           user: user.id,
//           course: 5,
//           chapter: chapterId,
//           subChapter: subChapterId,
//           score: userScore.toFixed(2),
//           total_score: totalScore.toFixed(2),
//         },
//         {
//           headers: {
//             Authorization: `Bearer ${authTokens?.access}`,
//           },
//         }
//       );
//       setSubmissionResponse(response.data);
//       Swal.fire('Success', 'Quiz results submitted successfully!', 'success');
//     } catch (error) {
//       setSubmissionResponse('Failed to submit quiz results.');
//       Swal.fire('Error', 'Failed to submit quiz results.', 'error');
//     } finally {
//       setIsLoading(false); // Hide loading spinner
//     }
//   };

//   const attendedQuestionsCount = Object.keys(selectedAnswers).length;

//   return (
//     <div className="quiz-container">
//       <h6>Practice Questions</h6>
//       <br />

//       <div className="attendance-count">{attendedQuestionsCount} Questions Attended</div>

//       {error && <div>Error: {error.message}</div>}

//       {!isLoaded && !error && <div>Loading...</div>}

//       {isLoaded && mergedItemsArray.length === 0 && <div>No items to display</div>}

//       {isLoaded && mergedItemsArray.length > 0 && (
//         <ul style={{ listStyleType: 'none', paddingLeft: '0', marginRight: '10px' }}>
//           {mergedItemsArray.map((item) => (
//             <li key={item.id}>
//               <br />
//               <h6>
//                 <div ref={questionRef} style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//                   <span style={{ marginRight: '10px', whiteSpace: 'nowrap' }}>Q {questionNumber++}:</span>
//                   <span style={{ flex: 1 }}>{parse(item.question)}</span>
//                 </div>
//               </h6>

//               <ul style={{ listStyleType: 'none', padding: '0', margin: '0', textAlign: 'left' }}>
//                 <li
//                   className={getOptionClassName(item.id, '1')}
//                   onClick={() => handleOptionClick(item.id, '1')}
//                   style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//                 >
//                   <span className="option-text">{parse(item.option1)}</span>
//                 </li>
//                 <li
//                   className={getOptionClassName(item.id, '2')}
//                   onClick={() => handleOptionClick(item.id, '2')}
//                   style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//                 >
//                   <span className="option-text">{parse(item.option2)}</span>
//                 </li>
//                 <li
//                   className={getOptionClassName(item.id, '3')}
//                   onClick={() => handleOptionClick(item.id, '3')}
//                   style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//                 >
//                   <span className="option-text">{parse(item.option3)}</span>
//                 </li>
//                 <li
//                   className={getOptionClassName(item.id, '4')}
//                   onClick={() => handleOptionClick(item.id, '4')}
//                   style={{ ...optionStyle, marginBottom: '5px', padding: '10px', cursor: 'pointer' }}
//                 >
//                   <span className="option-text">{parse(item.option4)}</span>
//                 </li>
//               </ul>
//               {showResultDetails && (
//                 <div className="result-details">
//                   <p>Correct Option: {item.correctOpt}</p>
//                   <div className="result-details" style={{ display: 'flex', alignItems: 'flex-start', textAlign: 'left' }}>
//                     <p>Explanation: {parse(item.explanation)}</p>
//                   </div>
//                 </div>
//               )}
//             </li>
//           ))}
//         </ul>
//       )}

//       {attendedQuestionsCount === mergedItemsArray.length && !showResultButton && (
//         <button className="submit-button" onClick={handleResultSubmit}>
//           Submit
//         </button>
//       )}

//       {showResultButton && (
//         <button className="show-result-button" onClick={handleShowResultClick}>
//           Show Result
//         </button>
//       )}

//       {isLoading && <LoadingSpinner />} {/* Show LoadingSpinner when isLoading is true */}
//       {console.log(submissionResponse)}
//     </div>
//   );
// };

// export default SubChapterWiseExam;
